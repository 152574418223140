import { combineReducers } from "redux";
import routeReducer from "./routeReducer";
import vehiclesReducer from "./vehicleReducer";
import metricsReducer from "./metricsReducer";
import popupReducer from "./popupReducer";
import vehicleSelectionReducer from "./vehicleSelectionReducer";
import vehicleListReducer from "./vehicleListReducer";
import mapDateSelectionReducer from "./mapDateSelectionReducer";
import settingsReducer from "./settingsReducer";
import snapshotVehiclesReducer from "./snapshotVehicleReducer";
import snapshotMetricsReducer from "./snapshotMetricsReducer";
import faultCodesReducer from "./faultCodesReducer";
import configReducer from "./configReducer";

const rootReducer = combineReducers({
  vehicleRoutes: routeReducer,
  vehicle: vehiclesReducer,
  metrics: metricsReducer,
  popupView: popupReducer,
  vehicleSelection: vehicleSelectionReducer,
  vehicleList: vehicleListReducer,
  mapDate: mapDateSelectionReducer,
  settings: settingsReducer,
  snapshotVehicle: snapshotVehiclesReducer,
  snapshotMetrics: snapshotMetricsReducer,
  faultCodes: faultCodesReducer,
  config: configReducer,
});

export default rootReducer;

//export type State = ReturnType<typeof rootReducer>;
