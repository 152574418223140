export const SNAPSHOT_METRICS_LOADING = "SNAPSHOT_METRICS_LOADING";
export const SNAPSHOT_METRICS_SUCCESS = "SNAPSHOT_METRICS_SUCCESS";

export type SnapshotMetrics = {
  id: string;
  assetNumber: string;
  dates: string[];
  lastLoadCurrent: number | null;
  lastSystemTemp: number | null;
  lastDate: string;
  lastStateOfCharge: number;
  signalStrength: number | null;
  SPSystemStatus: number | string | null;
  stealthHours: number;
  lastSolarAmps: number | null;
  lastShoreline: number | null;
  lastOEMVoltage: number | null;
  lastOdometer: number | null;
  lastFuelLevel: number | null;
  lastMastAmps: number | null;
  lastTiltAngle: number | null;
  lastMastEmergencyStop: boolean | null;
  lastTiltControl: boolean | null;
  lastMastControl: boolean | null;
  lastMoveUp: boolean | null;
  lastMoveDown: boolean | null;
  lastTilt0: boolean | null;
  lastTilt90: boolean | null;
  lastMastNested: boolean | null;
  lastMastExtended: boolean | null;
  lastTiltLock: boolean | null;
  lastMastHeight: number | null;
  lastMastCycles: number | null;
  lastTiltCycles: number | null;
};

export interface SnapshotMetricsLoading {
  type: typeof SNAPSHOT_METRICS_LOADING;
}

export interface SnapshotMetricsSuccess {
  type: typeof SNAPSHOT_METRICS_SUCCESS;
  payload: Array<SnapshotMetrics>;
}

export type SnapshotMetricsDispatchTypes =
  | SnapshotMetricsLoading
  | SnapshotMetricsSuccess;
