import { ReactElement, ReactNode } from "react";
export const SNAPSHOT_VEHICLES_LOADING = "SNAPSHOT_VEHICLES_LOADING";
export const SNAPSHOT_VEHICLES_FAILURE = "SNAPSHOT_VEHICLES_FAILURE";
export const SNAPSHOT_VEHICLES_SUCCESS = "SNAPSHOT_VEHICLES_SUCCESS";

export type SnapshotVehicles = {
  id: string;
  AssetNumber: string;
  values: Array<SnapshotVehicleData>;
  Location: string;
};

export type SnapshotVehicleData = {
  Date: string;
  DeviceName: string;
  StealthBatteryAmps: number;
  StealthBatteryVolts: number;
  SolarAmps: number;
  ShorelineVolts: number;
  SystemTemperature: number;
  StateOfCharge: number;
  OEMBatteryVoltage: number;
  FuelLevel: number;
  BMSMaxCellVolts: number;
  RSSI: number;
  BMSPackVolts: number;
  SystemState: number;
  Odometer: number;
  //Mast
  MastAmps: number;
  TiltAngle: number;
  MastEmergencyStop: boolean;
  TiltControl: boolean;
  MastControl: boolean;
  MoveUp: boolean;
  MoveDown: boolean;
  Tilt0: boolean;
  Tilt90: boolean;
  MastNested: boolean;
  MastExtended: boolean;
  TiltLock: boolean;
  MastHeight: number;
  MastCycles: number;
  TiltCycles: number;
};

export interface SnapshotVehicleLoading {
  type: typeof SNAPSHOT_VEHICLES_LOADING;
}

export interface SnapshotVehicleFailure {
  type: typeof SNAPSHOT_VEHICLES_FAILURE;
}

export interface SnapshotVehicleSuccess {
  type: typeof SNAPSHOT_VEHICLES_SUCCESS;
  payload: Array<SnapshotVehicles>;
}

export type SnapshotVehicleDispatchTypes =
  | SnapshotVehicleLoading
  | SnapshotVehicleFailure
  | SnapshotVehicleSuccess;
