import { ReactElement, ReactNode } from "react";
export const CONFIG_LOADING = "CONFIG_LOADING";
export const CONFIG_FAILURE = "CONFIG_FAILURE";
export const CONFIG_SUCCESS = "CONFIG_SUCCESS";

export type Config = {
  id: number;
  DCM: string;
  Timezone: string;
  LastUpdate: string;
  UpdateBy: string;
  RetractMast?: boolean;
  StowCESTilt?: boolean;
  EmergencyStop?: boolean;
};

export interface ConfigLoading {
  type: typeof CONFIG_LOADING;
}

export interface ConfigFailure {
  type: typeof CONFIG_FAILURE;
}

export interface ConfigSuccess {
  type: typeof CONFIG_SUCCESS;
  payload: Array<Config>;
}

export type ConfigDispatchTypes = ConfigLoading | ConfigFailure | ConfigSuccess;
