import {
  ConfigDispatchTypes,
  CONFIG_LOADING,
  CONFIG_FAILURE,
  CONFIG_SUCCESS,
  Config,
} from "../actions/configActionTypes";

interface DefaultStateInterface {
  loading: boolean;
  config?: Array<Config>;
  hasData?: boolean;
}

const defaultState: DefaultStateInterface = {
  loading: false,
  hasData: false,
};

const configReducer = (
  state: DefaultStateInterface = defaultState,
  action: ConfigDispatchTypes
): DefaultStateInterface => {
  switch (action.type) {
    case CONFIG_FAILURE:
      return {
        loading: false,
      };
    case CONFIG_LOADING:
      return {
        loading: true,
      };
    case CONFIG_SUCCESS:
      return {
        loading: false,
        config: action.payload,
        hasData: true,
      };
    default:
      return state;
  }
};

export type ConfigRootState = ReturnType<typeof configReducer>;

export default configReducer;
